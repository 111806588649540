
@import 'motion-ui/motion-ui';

@include motion-ui-transitions;
@include motion-ui-animations;

body, html {
  margin: 0;
  padding: 0;
}
